import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';
import Spinner from '../../components/Spinner';
import {
  TextField,
  Button,
  Typography,
  Grid,
  Link,
  Checkbox,
  Box,
  Paper,
  Container,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import * as Yup from "yup";
import backgroundimage from "../../images/arvam-login-2-base.png";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from 'formik';


function Login() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )
  if (isError) {
    toast.error(message);
    dispatch(reset())
  }
  if (isSuccess || user) {
    navigate('/')
  }
  if (isLoading) {
    return <Spinner />
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string().required('Password is required'),
  })

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
        }}>
        <Grid container>
          <Grid
            item
            xs={false}
            sm={5}
            md={7}
            sx={{
              backgroundImage: 'url(' + backgroundimage + ')',
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.primary[100]
                  : t.palette.grey[600],
              backgroundSize: "96%",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h3">
                Sign in
              </Typography>

                <Formik
                  key={"login"}
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const userData = {
                      email: values["email"],
                      password: values["password"]
                    }
                    dispatch(login(userData))
                  }}
                  // Need to expand the validation schema more later
                  validationSchema={validationSchema} >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      resetForm
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          margin="normal"
                          error={errors['email'] && touched['email']}
                          onBlur={handleBlur}
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          onChange={handleChange}
                          autoComplete="email"
                          autoFocus
                        />
                        <FormHelperText style={{ color: 'red', fontSize: '14px' }}>{errors['email'] && touched['email'] ? errors['email'] : ""}</FormHelperText>
                        <TextField
                          margin="normal"
                          error={errors['password'] && touched['password']}
                          onBlur={handleBlur}
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          onChange={handleChange}
                          id="password"
                          autoComplete="current-password"
                        />
                        <FormControlLabel
                          control={<Checkbox value="remember" color="primary" />}
                          label="Remember me"
                        />
                        <Button type="submit" variant="contained" color="primary"
                          fullWidth
                          sx={{ mt: 3, mb: 2 }} data-cy="submit">
                          
                          Sign In
                        </Button>
                        <Grid container>
                          <Grid item xs>
                            <Link href="/forgot" variant="body2" color={colors.primary[100]}>
                              Forgot password?
                            </Link>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Login