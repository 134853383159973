// Additional Fields Information
// venum : List of enumerated values, 'select' dropdown box will be used to display
// skip  : if true, this field will not be displayed in the form
// edit  : if true, field is editable in the update/add forms ealse read only
// label :
// select :

export const userModel = [
    { field: "firstname", type: String, required: true },
    { field: "lastname", type: String, required: true },
    { field: "email", type: String, required: true },
    { field: "age", type: Number, required: true },
    { field: "phone", type: String, required: true },
    { field: "access", type: String, required: true, venum: ["USER", "ADMIN"] },
    { field: "dept", type: String, required: true, value: true, venum: [], alt: "dept" },
    { field: "employeetype", type: String, required: true, default: "FT", venum: ["FT", "PT"] },
    { field: "designation", type: String, required: true },
    { field: "payrate", type: Number, required: false, default: 0 }
];

export const assetModel = [
    { field: "assetid", type: String, label: 'Asset Id', required: true, edit: false },
    { field: "name", type: String, label: 'Name', required: true, edit: false },
    { field: "desc", type: String, label: 'Description', required: true, width: 500 },
    { field: "family", type: String, label: 'Family', required: true, value: true, venum: [], alt: "family" },
    { field: "state", type: String, label: 'Asset State', required: true, venum: ["", "Operational", "Suspended", "Decommissioned"], skip: false },
    { field: "inspectionState", type: String, label: 'Inspection State', required: false, skip: true, view: true },
    { field: "assettype", type: String, label: 'Asset Type', required: true, venum: ["", "Asset", "Asset-T", "Logical-C", "Logical-D"] },
    { field: "assetclass", type: String, label: 'Asset Class', required: true, venum: ["", "A", "B", "C"] },
    { field: "section", type: String, label: 'Section', required: true, value: true, venum: [], alt: "section" },
    { field: "subsection", type: String, label: 'Sub Section', required: false, value: true, venum: [], alt: "subsection" },
    { field: "parentassetid", type: String, label: 'Parent Asset Id', required: false, value: true, venum: [], alt: "parentassetid", condition: true, multipleCondition: true, conditionField: [{ condition: 'assettype', conditionValue: 'Asset' }, { condition: 'assettype', conditionValue: 'Asset-T' }] },
    { field: "approval", type: Boolean, label: 'Approval', default: false, required: true, venum: ["Yes", "No"] },
    { field: "approvalchain", type: Array, label: 'Approval Chain', },
    { field: "meters", label: "Meters", isLable: true },
    { field: "meter", type: String, label: 'Meter', required: true, venum: ["No-Meter", "Manual", "Online"] },
    { field: "reset", type: Number, label: 'Reset', required: true, venum: ["Yes", "No"], condition: true, conditionField: 'meter', conditionValue: 'Manual' },
    { field: "unit", type: Number, label: 'Unit', required: true, value: true, venum: [], alt: "unit", condition: true, conditionField: 'meter', conditionValue: 'Manual' },
    { field: "specification", label: "Specification", isLable: true },
    { field: "manufacturedate", type: Date, label: 'Manufacture Date', required: true },
    { field: "installationdate", type: Date, label: 'Installation Date', required: true },
    { field: "warranty", type: String, label: 'Warranty', required: true, venum: ["Yes", "No"], },
    { field: "warrantydate", type: Date, label: 'Warranty Date', required: true, alt: "warrantydate", isFuture: true, condition: true, conditionField: 'warranty', conditionValue: 'Yes' },
    { field: "geo", type: Array, label: 'Geo', required: false, default: [0, 0] },
    { field: "params", type: Array, label: 'Params', skip: true },
    { field: "cost", type: Number, label: 'Cost', },
    { field: "suspendedfrom", type: Date, label: 'Suspended From', skip: true },
    { field: "suspendedto", type: Date, label: 'Suspended To', skip: true },
    { field: "suspensionreason", type: String, label: 'Suspension Reason', required: false, condition: true, conditionField: 'state', conditionValue: 'Suspended' },
    { field: "laststatechange", type: Date, label: 'Lasts State Change', skip: true },
    { field: "lastupdated", type: Date, label: 'Last Updated', skip: true },
    { field: "iiotenabled", type: Boolean, label: 'IIOT Enabled', default: false, venum: ["Yes", "No"] },
    { field: "fileUrl", type: File, label: 'Upload Assest Image', required: true, skip: false, select: false, datePicker: false, edit: true, format: '.png, .jpg, .jpeg, .pdf' },
    { field: "videoUrl", type: File, label: 'Upload Assest Video Clips', required: true, skip: false, select: false, datePicker: false, edit: true, format: '.mp4, .avi, .mov' },
];

export const facilityModel = [
    { field: "name", label: 'Name', type: String, required: true, fkey: false, skip: false },
    { field: "type", label: 'Type', type: String, required: true, fkey: false, select: true, venum: [], value: true, skip: false },
    { field: "currency", label: 'Currency', type: String, required: true, fkey: false, select: true, venum: [], value: true, skip: false, condition: true, conditionField: 'type', conditionValue: 'Facility', },
    { field: "parent", label: 'Parent', type: String, required: false, fkey: false, select: true, venum: [], value: true, skip: false },
    { field: "cdate", label: 'Created Date', type: String, required: true, fkey: true, skip: true },
    { field: "createdBy", label: 'Created By', type: String, required: true, fkey: true, skip: true },
];

export const deptModel = [
    { field: "name", label: 'Name', type: String, required: true, fkey: true, skip: false },
    { field: "desc", label: 'Description', type: String, required: false, fkey: true, skip: false },
    { field: "costcentercode", label: 'Cost center Code', type: String, required: true, fkey: true, skip: false },
    { field: "reconcode", label: 'Recon Code', type: String, required: false, fkey: true, skip: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: true },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: true },
];

export const inventoryModel = [
    { field: "itemname", label: 'Name', type: String, required: true, fkey: false, skip: false, view: true },
    { field: "itemcode", label: 'Code', type: String, required: true, fkey: false, skip: false, view: true },
    { field: "unit", label: 'Units', type: String, required: true, fkey: false, skip: false, view: true, select: true, venum: [], value: true },
    { field: "itemtype", label: 'Type', type: String, required: true, fkey: false, skip: false, select: true, venum: ['Spare', 'Consumable', 'Tool'], view: true },
    { field: "vendor", label: 'Primary Vendor', type: String, required: true, fkey: false, skip: false, view: true, select: true, venum: [], value: true },
    { field: "spareCostClass", label: 'Spare Cost Class', type: String, required: true, fkey: false, skip: false, select: true, venum: ['High Value Item', 'Medium Value Item', 'Low Value Item'], view: false },
    { field: "spareMovementClass", label: 'Spare Movement Class', type: String, required: true, fkey: false, skip: false, select: true, venum: ['Fast Moving', 'Slow Moving', 'Non Moving'], view: false },
    { field: "spareCriticalityClass", label: 'Spare Criticality Class', type: String, required: true, fkey: false, skip: false, select: true, venum: ['Vital', 'Essential', 'Desirable'], view: false },
    { field: "reorderthreshold", label: 'Reorder Threshold', type: String, required: true, fkey: false, skip: false, view: false },
    { field: "autoreorder", label: 'Auto Reorder', type: String, required: true, fkey: false, skip: false, select: true, venum: ["Yes", "No"], view: false },
    { field: "unitcost", label: 'Unit Cost', type: String, required: true, fkey: false, skip: false, view: true },
    { field: "store", label: 'Store', type: String, required: true, fkey: false, skip: false, view: true, select: true, venum: [], value: true },
    { field: "reorderQty", label: 'Reorder Quantity', type: String, required: true, fkey: false, skip: false, view: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false, view: true },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false, view: true },
]

export const maintenanceLibModel = [
    { field: "code", type: String, required: true, view: true, 'label': "Code", edit: false },
    { field: "name", type: String, required: true, view: true, 'label': "Name", edit: false },
    { field: "dept", type: String, required: true, venum: [], value: true, 'label': "Department" },
    { field: "state", type: String, view: true, venum: ["", "ACTIVE", "SUSPENDED"], 'label': "State" },
    { field: "desc", type: String, required: true, view: true, 'label': "Description", multiline: true, width: 500 },
    { field: "cdate", type: Array, skip: true, view: true, 'label': "Created Date" },
    { field: "createdBy", type: Array, skip: true, view: true, 'label': "Created By" },
    { field: "suspensionreason", type: String, view: true, 'label': "Suspension Reason", condition: true, conditionField: 'state', conditionValue: 'SUSPENDED', },
    { field: "suspendedfrom", type: Date, skip: true, view: true, 'label': "Suspended Date" },
    { field: "tasklist", type: Array, skip: true, view: false, 'label': "TaskList" },
];

export const mtaskModel = [
    { field: "mlid", type: String, required: true, fkey: true, skip: true },
    { field: "name", type: String, required: true, label: "Task Name" },
    { field: "desc", type: String, required: true, label: "Description" },
    { field: "spares", type: Array, skip: true, subfield: "qty", label: "Spares" },
    { field: "createdon", type: Date, skip: true },
    { field: "dept", type: String, required: true, venum: [], value: true, 'label': "Department" },
    { field: "complete", type: String, venum: ["Yes", "No"], default: "No", required: true, label: "Complete?" },
    { field: "trade", type: String, label: "Trade" },
    { field: "startdate", type: Date, label: "StartDate" },
    { field: "enddate", type: Date, label: "EndDate" },
]

export const mparamsModel = [
    { field: "maintenancelibrary", type: String, required: true },
    { field: "schedule", type: String, required: true, enum: ["Monthly", "Weekly"] },
    { field: "Start Date", type: Date },
    { field: "Status", type: String }
]


export const maintenanceModel = [
    { field: "mainlibcode", type: String, label: 'Maintenance Library Code', required: true, alt: "Maintenance Library", venum: [], value: true, isView: true, edit: false },
    { field: "section", type: String, required: true, skip: false, edit: true, label: 'Section', select: true, venum: [], value: true, },
    { field: "assetid", type: String, alt: "asset", label: 'Asset Id', required: true, venum: [], value: true, isView: true, edit: false },
    { field: "assetname", type: String, label: 'Asset Name', required: true, skip: true, isView: false, edit: false },
    { field: "assettype", type: String, label: 'Asset Type', required: true, skip: true, isView: false, },
    { field: "dept", type: String, label: 'Department', venum: [], value: true, isView: true },
    { field: "status", type: String, label: 'Status', required: true, skip: true, venum: ["ACTIVE", "SUSPENDED"], default: "ACTIVE", isView: true, },
    { field: "type", type: String, label: 'Type', required: true, venum: ["Preventive", "Corrective", "Breakdown"], isView: true, edit: false },
    { field: "approvalrequired", type: String, required: true, skip: false, label: 'Approval Required', select: true, venum: ['true', 'false'], edit: true },
    { field: "approvallevel", type: String, required: true, skip: false, label: 'Approval Level', select: true, venum: [], value: true,  edit: true, condition: true, conditionField: 'approvalrequired', conditionValue: 'true', },
    { field: "safetyclearance", type: String, required: true, skip: false, select: true, label: 'Safety Clearance', venum: ['true', 'false'], edit: true },
    { field: "frequency", type: String, label: 'Frequency', required: true, venum: [], value: true, isView: true },
    { field: "createddate", type: Date, label: 'Created Date', skip: false, isView: true },
    { field: "desc", type: String, label: 'Description', required: true, skip: true, isView: true },
    { field: "suspendeddate", type: Date, label: 'Suspended Date', skip: true, isView: true },
    { field: "assetmovement", type: Date, label: 'Asset Movement', skip: true, isView: true },
    { field: "externalVendor", type: Date, label: 'External Vendor', skip: true, isView: true },
    { field: "thresloadValue", type: Date, label: 'Meter Threshold Value', skip: true, isView: true },
    { field: "substatus", type: String, label: 'Sub Status', required: true, venum: ["NONE", "DELAYED", "ONTIME"], default: "NONE", skip: true, isView: false },
    { field: "comments", type: String, label: 'Comments', skip: false, isView: true }
];

export const workOrderModel = [
    { field: "assetid", type: String, label: 'Asset Id', required: true, edit: false },
    { field: "wonumber", type: String, label: 'WO Number', required: true, edit: false },
    { field: "type", type: String, label: 'Type', required: true, edit: false },
    { field: "wrnumber", type: String, label: 'WR Number', skip: true },
    { field: "assignee", type: String, label: 'Assignee', venum: [], value: true },
    { field: "dept", type: String, label: 'Dept', skip: true, edit: false },
    { field: "state", type: String, label: 'State', venum: ["Scheduled", "Created", "Completed"] },
    { field: "severity", type: Number, label: 'Severity', default: 2 },
    { field: "section", type: Number, label: 'Section' },
    { field: "subsection", type: Number, label: 'Sub Section' },
    { field: "fpStatus", type: String, label: 'FP Status', },
    { field: "createddate", type: Date, label: 'Created Date', edit: false, skip: false },
    { field: "startdate", type: Date, label: 'Start Date', },
    { field: "finishdate", type: Date, label: 'Finish Date', },
    { field: "totalworkorderhours", type: Date, label: 'Total Working Hr', },
    { field: "isMeter", type: Date, label: 'Meter', edit: false, skip: true },
    { field: "approvalrequired", type: Boolean, label: 'Approval Required', default: false },
    { field: "breakdowncode", type: String, label: 'Breakdown Code', venum: [], value: true, condition: true, conditionField: 'type', conditionValue: 'Breakdown', },
    { field: "affectingproduction", type: String, label: 'Affecting Production', venum: ['Yes', 'No'], value: false, condition: true, conditionField: 'type', conditionValue: 'Breakdown', },
    { field: "productionlosscode", type: String, label: 'Production Loss Code', venum: [], value: true, condition: true, conditionField: 'affectingproduction', conditionValue: 'Yes', },
    { field: "notes", type: String, label: 'Notes', width: 500, multiline: true },
    { field: "modifiedBy", type: String, label: 'Modified By', skip: true },
];

export const workRequestModel = [
    { field: "wrnumber", type: String, label: 'WR Number', required: true, skip: true, edit: false },
    { field: "wonumber", type: String, label: 'WO Number', skip: true },
    { field: "assetid", type: String, label: 'Asset Id', alt: "asset", required: true, venum: [], value: true, edit: false, width: 300 },
    { field: "state", type: String, label: 'State', venum: ["Assigned", "Accepted", "Completed", "Suspended", "Rejected"], skip: true },
    { field: "wostate", type: String, label: 'WO State', skip: true },
    { field: "desc", type: String, label: 'Description', width: 500, view: false },
    { field: "createdby", type: String, label: 'Created By', skip: true },
    { field: "assignee", type: String, label: 'Assignee', skip: true },
    { field: "assigneedept", type: String, label: 'Assignee Dept', skip: true, view: false },
    { field: "assigneddept", type: String, label: 'Assigned Dept', alt: 'dept', venum: [], value: true },
    { field: "createddate", type: Date, label: 'Created Date', skip: true },
    { field: "finishrequestdate", label: "Finish Date", type: Date, skip: false },
    { field: "suspendeddate", type: Date, label: 'Suspended Date', skip: true, view: false },
    { field: "notes", type: Array, label: 'Notes', width: 500, multiline: true, view: false },
    { field: "rejectreason", type: String, label: 'Accept/Reject Reason', skip: true, view: false },
];

export const inspectionLibModel = {
    inspectionid: { type: String, required: true },
    name: { type: String, required: true },
    desc: { type: String, required: true },
    dept: { type: String, required: true, enum: [], value: true },
    createddate: { type: Date, skip: true, view: false },
    suspendedfrom: { type: Date, skip: true, view: false },
    suspendedto: { type: Date, skip: true, view: false },
    suspensionreason: { type: String, skip: true, view: false },
    inspectiontype: { type: String, venum: ["Inspection", "Measured"] },
    tasklist: { type: Array, skip: true },
    status: { type: String, enum: ["", "ACTIVE", "SUSPENDED", "DEPRECATED"] },
    param: { type: String }
};

export const failurePreventionModel = [
    { field: "assetId", field2: 'assetid', label: 'Asset Id', type: String, required: true, edit: false, populate: true, },
    { field: "workorderId", field2: 'wonumber', label: 'Workorder Id', type: String, required: true, edit: false, populate: true, },
    { field: "duration", label: 'Duration', type: String, required: true, edit: false, populate: false, },
    { field: "code", label: 'Code', type: String, required: true, edit: false, populate: false, },
    { field: "state", label: 'State', type: String, required: true, edit: false, populate: false, },
    { field: "preventionCase", label: 'Prevention Case', type: String, skip: true, edit: false, populate: false, },
    { field: "actionTaken", label: 'Action Taken', type: String, skip: true, edit: false, populate: false, },
    { field: "maintenanceChange", label: 'Maintenance Charge', type: String, skip: true, edit: false, populate: false, },
    { field: "inspectionChange", label: 'Inspection Chage', type: String, skip: true, edit: false, populate: false, },
    { field: "createdBy", label: 'Created By', type: String, edit: false, populate: false, },
    { field: "cdate", label: 'Created Date', type: String, edit: false, populate: false, },
    { field: "modifiedBy", label: 'Modified By', type: String, edit: false, populate: false, },
    { field: "mdate", label: 'Modified Date', type: String, edit: false, populate: false, },
];


export const inspectionAspectsModel = [
    { field: "aspect", type: String, required: true, label: 'Aspect', select: false, measuredValue: false },
    { field: "parameter", type: String, required: true, label: 'Parameter', select: true, enum: [], measuredValue: false },
    { field: "inspectionType", type: String, required: true, label: 'Inspection Type', select: true, enum: ['Measured', 'Visual'], measuredValue: false },
    { field: "inspectionBasic", type: String, required: true, label: 'Inspection Basis', select: true, enum: ['Predictive', 'Regulatory'], measuredValue: false },
    { field: "type", type: String, required: true, select: true, label: 'Inspection Method', enum: ['Manual', 'Visual', 'Online'], measuredValue: false },
    { field: "department", type: String, required: true, label: 'Department', select: true, enum: [], measuredValue: false },
    { field: "greenCategory", type: String, required: true, label: 'Green Category', select: true, enum: ['Greater than Equal', 'Less than Equal', 'Min-Max'], measuredValue: true },
    { field: "green", type: String, required: true, label: 'Green', select: false, measuredValue: true },
    { field: "greenMin", type: String, required: true, label: 'Green Min', select: false, measuredValue: true },
    { field: "greenMax", type: String, required: true, label: 'Green Max', select: false, measuredValue: true },
    { field: "yellowCategory", type: String, required: true, label: 'Yellow Category', select: true, enum: ['Absolute', 'Min-Max'], measuredValue: true },
    { field: "yellow", type: String, required: false, label: 'Yellow', select: false, measuredValue: true },
    { field: "yellowMin", type: String, required: true, label: 'Yellow Min', select: false, measuredValue: true },
    { field: "yellowMax", type: String, required: true, label: 'Yellow Max', select: false, measuredValue: true },
    { field: "redCategory", type: String, required: true, label: 'Red Category', select: true, enum: ['Absolute', 'Min-Max'], measuredValue: true },
    { field: "red", type: String, required: true, label: 'Red', select: false, measuredValue: true },
    { field: "redMin", type: String, required: true, label: 'Red Min', select: false, measuredValue: true },
    { field: "redMax", type: String, required: true, label: 'Red Max', select: false, measuredValue: true },
];

export const inspectionModel = [
    { field: "section", type: String, required: true, skip: false, edit: true, label: 'Section', select: true, venum: [], value: true, },
    { field: "assetId", field2: 'assetid', type: String, populate: true, required: true, skip: false, label: 'Asset Id', select: true, enum: [], value: true, edit: false },
    { field: "department", type: String, required: true, skip: false, label: 'Department', select: true, enum: [], value: false, edit: false },
    { field: "inspectionCategory", type: String, required: true, skip: false, multiple: true, label: 'Inspection Category', select: true, enum: ['Predictive', 'Regulatory'], edit: false },
    { field: "aspect", type: String, required: true, skip: false, multiple: true, label: 'Aspect', select: true, enum: [], value: false, edit: true },
    { field: "schedulingFrequency", type: String, required: true, skip: false, label: 'Scheduling Frequency', select: true, enum: ['Daily', 'Weekly', 'Monthly', 'Six Month', 'Yearly', 'One Time'], edit: true },
    { field: "assignee", type: String, required: true, skip: false, label: 'Assignee', select: true, enum: [], value: true, edit: false, condition: true, conditionField: 'schedulingFrequency', conditionValue: 'One Time', },
    { field: "state", type: String, required: false, skip: false, label: 'State', select: true, enum: ['ACTIVE', 'SUSPENDED'], edit: true },
    { field: "createdOn", type: Date, required: false, skip: false, label: 'Created Date', select: false, edit: true },
    { field: "date", type: Date, required: true, skip: true, label: 'Date', select: false, edit: true },
    { field: "approvalRequired", type: String, required: true, skip: false, label: 'Approval Required', select: true, enum: ['true', 'false'], edit: true },
    { field: "approvallevel", type: String, required: true, skip: false, label: 'Approval Level', select: true, enum: ['One', 'Two'], edit: true, condition: true, conditionField: 'approvalRequired', conditionValue: 'true', },
    { field: "safetyClearance", type: String, required: true, skip: false, select: true, label: 'Safety Clearance', enum: ['true', 'false'], edit: true },
    { field: "suspensionreason", type: Date, required: false, skip: false, label: 'Suspension Reason', select: false },
];

export const resourceModel = [
    { field: "firstname", type: String, required: true, skip: false, edit: false, label: 'First Name', select: false, edit: true, },
    { field: "lastname", type: String, required: true, skip: false, edit: true, label: 'Last Name', select: false, edit: true },
    { field: "userid", type: String, required: true, skip: false, edit: true, label: 'User Id', select: false, edit: true },
    { field: "email", type: String, required: true, skip: false, edit: true, label: 'Email', select: false, edit: true },
    { field: "phone", type: String, required: true, skip: false, edit: true, label: 'Phone', select: false, edit: true },
    { field: "department", type: String, required: true, skip: false, edit: true, label: 'Department', select: true, venum: [], value: true },
    { field: "designation", type: String, required: true, skip: false, edit: true, label: 'Designation', select: false, edit: true },
    { field: "payrate", type: String, required: true, skip: false, edit: true, label: 'Pay Rate / Per Hour', select: false, edit: true },
    { field: "employeetype", type: String, required: true, skip: false, edit: true, label: 'Employee Type', select: true, venum: ['Permanent', 'Contractor'], edit: true },
    { field: "contract", type: String, required: true, skip: false, edit: true, label: 'Contract', select: true, venum: [], value: true, condition: true, conditionField: 'employeetype', conditionValue: 'Contractor', edit: true },
    { field: "accesscategory", type: String, required: true, skip: false, edit: true, label: 'Access Category', select: true, venum: [], value: true, edit: false },
    { field: "dob", type: Date, required: true, skip: false, label: 'DOB', edit: true, select: false, datePicker: true, edit: true },
    { field: "notification", type: String, required: true, skip: false, edit: true, label: 'Notification', select: true, venum: ['Email', 'Whatsapp'], multiple: true, },
    { field: "approvallevel", type: String, required: true, skip: false, edit: true, label: 'Approval Level', select: true, venum: ['L1', 'L2', 'None'], multiple: false, },
    { field: "safetyofficer", type: String, required: true, skip: false, edit: true, label: 'Safety Officer', select: true, venum: ['Yes', 'No'], multiple: false, },
    { field: "section", type: String, required: true, skip: false, edit: true, label: 'Section', select: true, venum: [], value: true, multiple: true, },
    { field: "isActive", type: Boolean, required: false, fkey: true, skip: false, edit: true, label: 'Active', },
    { field: "cdate", type: Boolean, required: false, fkey: true, skip: false, edit: true, label: 'Created Date', },
    { field: "createdBy", type: Boolean, required: false, fkey: true, skip: false, edit: true, label: 'Created By', },
];



export const assetfamilyModel = [
    { field: "name", type: String, required: true, skip: true, isView: true, label: 'Asset Family Name' },
    { field: "code", type: String, required: true, skip: true, isView: true, label: 'Asset Family Code' },
    { field: "description", type: String, required: true, skip: true, isView: true, label: 'Asset Family Desc' },
    { field: "cdate", type: String, required: true, skip: true, isView: true, label: 'Created Date' },
    { field: "createdBy", type: String, required: true, skip: true, isView: true, label: 'Created By' },
    { field: 'label', label: 'Specification', isLable: true, skip: false, isView: false },
    { field: "fieldname", type: String, required: true, skip: false, isView: false, label: 'Field Name' },
    { field: "description", type: String, required: false, skip: false, isView: false, label: 'Description' },
    { field: "datatype", type: String, required: true, skip: false, isView: false, label: 'Data Type', select: true, enum: ['Alphanumeric', 'Numeric'] },
    { field: "units", type: String, required: false, skip: false, isView: false, label: 'Units', select: true, enum: [], condition: true, conditionField: 'datatype', conditionValue: 'Numeric' },
];

export const contractorModel = [
    { field: "name", type: String, required: true, skip: false, label: 'Contractor Name' },
    { field: "contractType", type: String, required: true, skip: false, label: 'Contractor Type', select: true, venum: ['Manpower Contractor', 'Supply Contractor', 'Workshop Contractor'] },
    { field: "phone", type: String, required: true, skip: false, label: 'Phone' },
    { field: "email", type: String, required: true, skip: false, label: 'Email' },
    { field: "address", type: String, required: true, skip: false, label: 'Address' },
    { field: "cdate", type: Boolean, required: false, skip: true, label: 'Created Date', },
    { field: "createdBy", type: Boolean, required: false, skip: true, label: 'Created By', },
];

export const meterModel = [
    { field: "parameter", type: String, required: true, skip: false, label: 'Parameter Name' },
    { field: "unitType", type: String, required: true, skip: false, label: 'Unit Type', select: true, venum: ['Measurement', 'Meter', 'Inventory'] },
    { field: "metric", type: String, required: true, skip: false, label: 'Metric', condition: true, conditionField: 'unitType', conditionValue: 'Measurement' },
    { field: "imperial", type: String, required: true, skip: false, label: 'Imperial', condition: true, conditionField: 'unitType', conditionValue: 'Measurement' },
];

export const accessCategoryModel = [
    { field: "name", type: String, required: true, skip: false, label: 'Access Category Name' },
    { field: "cdate", type: String, required: true, skip: false, label: 'Created Date' },
    { field: "createdBy", type: String, required: true, skip: false, label: 'Created By' },
];

export const accesslistModel = [
    { field: "security", type: String, label: 'Security' },
    { field: "setup", type: String, label: 'Set Up' },
    { field: "asset", type: String, label: 'Asset' },
    { field: "item", type: String, label: 'Item' },
    { field: "store", type: String, label: 'Store' },
    { field: "procurement", type: String, label: 'Procurement' },
    { field: "main", type: String, label: 'Maintenance Library' },
    { field: "procurement", type: String, label: 'Inspection' },
    { field: "workcenter", type: String, label: 'Work Center' },
    { field: "failureprevenion", type: String, label: 'Failure Prevention' },
    { field: "report", type: String, label: 'Reports' },
    { field: "dashboard", type: String, label: 'Dashboards' },
    { field: "sensordata", type: String, label: 'Sensor Data' },
];

export const breakdowncodeModel = [
    { field: "code", label: 'Code', type: String, required: true, fkey: false, skip: false },
    { field: "type", label: 'Code Type', type: String, required: true, fkey: false, skip: false, select: true, venum: ['BR Code', 'PL Code'] },
    { field: "description", label: 'Description', type: String, required: false, fkey: false, skip: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: true },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: true },
];

export const vendorModel = [
    { field: "supplierCode", label: 'Supplier Code', type: String, required: true, fkey: false, skip: false, view: true },
    { field: "supplierName", label: 'Supplier Name', type: String, required: false, fkey: false, skip: false, view: true },
    { field: "email", label: 'Email', type: String, required: false, fkey: false, skip: false, view: true },
    { field: "phone", label: 'Phone No', type: String, required: false, fkey: false, skip: false, view: true },
    { field: "fax", label: 'Fax No', type: String, required: false, fkey: false, skip: false, view: false },
    { field: "street", label: 'Street', type: String, required: false, fkey: false, skip: false, view: false },
    { field: "city", label: 'City', type: String, required: false, fkey: false, skip: false, view: true },
    { field: "state", label: 'State', type: String, required: false, fkey: false, skip: false, view: true },
    { field: "country", label: 'Country', type: String, required: false, fkey: false, skip: false, view: false },
    { field: "zipCode", label: 'Zip Code', type: String, required: false, fkey: false, skip: false, view: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false, view: true },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false, view: true },
];

export const storeConfigModel = [
    { field: "name", label: 'Name', type: String, required: true, fkey: false, skip: false },
    { field: "type", label: 'Store Type', type: String, required: false, fkey: false, skip: false, select: true, venum: ['Sub Store', 'Yard'] },
    { field: "parent", label: 'Parent', type: String, required: false, fkey: false, skip: false, select: true, venum: [], value: true, },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false },
];

export const storeModel = [
    { field: "itemname", label: 'Item Name', type: String, required: true, fkey: false, skip: false },
    { field: "itemcode", label: 'Item Code', type: String, required: true, fkey: false, skip: false },
    { field: "quantity", label: 'Quantity', type: String, required: false, fkey: false, skip: false, select: true, venum: ['Sub Store', 'Yard'] },
    { field: "unit", label: 'Unit', type: String, required: false, fkey: false, skip: false, select: true, venum: [], value: true, condition: true, conditionField: 'type', conditionValue: 'Yard' },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false },
];

export const storeRequestModel = [
    { field: "wonumber", label: 'Work Order', type: String, required: true, fkey: false, skip: false },
    { field: "requestdate", label: 'Request Date', type: String, required: true, fkey: false, skip: false },
    { field: "itemname", label: 'Item Name', type: String, required: false, fkey: false, skip: false, },
    { field: "itemcode", label: 'Item Code', type: String, required: false, fkey: false, skip: false, },
    { field: "quantity", label: 'Quantity', type: String, required: false, fkey: true, skip: false },
    { field: "status", label: 'Status', type: String, required: false, fkey: true, skip: false },
];


export const procurementModel = [
    { field: "poref", label: 'PO Ref', type: String, required: true, fkey: true, skip: false },
    { field: "prmref", label: 'PRM Ref', type: String, required: true, fkey: false, skip: false },
    { field: "podate", label: 'PO Date', type: String, required: true, fkey: false, skip: false },
    { field: "department", label: 'Department', type: String, required: true, fkey: false, skip: false, select: true, venum: [], value: true },
    { field: "remarks", label: 'Remarks', type: String, required: true, fkey: false, skip: false },
    { field: "vendor", label: 'Vendor', type: String, required: true, fkey: false, skip: false, select: true, venum: [], value: true },
    { field: "prmcategory", label: 'PRF Category', type: String, required: false, fkey: true, skip: false },
    { field: "status", label: 'State', type: String, required: false, fkey: true, skip: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false },
]

export const procurementRequestModel = [
    { field: "prmref", label: 'PRM Ref', type: String, required: true, fkey: true, skip: false },
    { field: "prmdate", label: 'PRM Date', type: String, required: true, fkey: true, skip: false },
    { field: "department", label: 'Department', type: String, required: true, fkey: false, skip: false, select: true, venum: [], value: true },
    { field: "remarks", label: 'Remarks', type: String, required: false, fkey: false, skip: false },
    { field: "prmcategory", label: 'PRF Category', type: String, required: false, fkey: true, skip: false },
    { field: "status", label: 'Accept / Reject', type: String, required: false, fkey: true, skip: false },
    { field: "cdate", label: 'Created Date', type: String, required: false, fkey: true, skip: false },
    { field: "createdBy", label: 'Created By', type: String, required: false, fkey: true, skip: false },
    { field: "approvedBy", label: 'Approved By', type: String, required: false, fkey: true, skip: false },
    { field: "approvedDate", label: 'Approved Date', type: String, required: false, fkey: true, skip: false },
]

export const configurationModel = [
    { field: "name", label: "Config Name", type: String, required: true, skip: false },
    { field: "value", label: "Value", type: String, required: true, skip: false },
]

export const notificationSettModel = [
    { field: "title", label: "Name", type: String, required: true, skip: false },
    { field: "isEmail", label: "Email", type: String, required: true, skip: false },
    { field: "isSMS", label: "SMS", type: String, required: true, skip: false },
    { field: "isWhatsApp", label: "Whatsapp", type: String, required: true, skip: false },
]

export const workshopModel = [
    { field: "assetid", label: "Asset Id", type: String, required: true, skip: false },
    { field: "wonumber", label: "Workorder Number", type: String, required: true, skip: false },
    { field: "indate", label: "In Date", type: String, required: true, skip: false },
    { field: "outdate", label: "Out Date", type: String, required: true, skip: false },
    { field: "status", label: "Status", type: String, required: true, skip: false },
    { field: "cdate", label: "Created Date", type: String, required: false, skip: false },
    { field: "createdBy", label: "Created By", type: String, required: false, skip: false },
];


export const externalWorkshopModel = [
    { field: "assetid", label: "Asset Id", type: String, required: true, skip: false },
    { field: "wonumber", label: "Workorder Number", type: String, required: true, skip: false },
    { field: "indate", label: "In Date", type: String, required: true, skip: false },
    { field: "externaloutdate", label: "External Out Date", type: String, required: true, skip: false },
    { field: "status", label: "Status", type: String, required: true, skip: false },
    { field: "cdate", label: "Created Date", type: String, required: false, skip: false },
    { field: "createdBy", label: "Created By", type: String, required: false, skip: false },
];