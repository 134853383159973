import { Box, Typography, useTheme, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Tooltip, IconButton, TableContainer, TableRow, TableCell, Checkbox, Table, TableHead, TableBody, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify'
import { myConfig } from "../../settings.js";
import { useSelector } from 'react-redux';
import { apiService, apiService2 } from '../../features/apiService';
import { MaterialReactTable } from "material-react-table";
import { getAccess } from "../../features/helpers/authhelper";
import { notificationSettModel } from "../../schema/schema";
// import AddNotificationSett from "./add";
// import EditNotificationSett from "./edit";

const NotificationSett = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [refresh, setRefresh] = useState(false);
    const [notificationList, setNotificationSettList] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const access = getAccess(user, 'Set Up');

    const notiList = [
        { title: 'Asset', isEmail: false, isSMS: false, isWhatsApp: false },
        { title: 'Workorder', isEmail: false, isSMS: false, isWhatsApp: false },
    ]

    useEffect(() => {
        apiService2('GET', myConfig.getNotificationSettEP)
            .then(response => {
                var notifiList = response.data;
                console.log(notifiList);
                if (notifiList.length > 0) {
                    notifiList?.forEach(res => {
                        res.cdate = new Date(res.createdOn).toLocaleDateString()
                        res.parent = res.parent ? res.parent.name : '';
                    })
                    setNotificationSettList(notifiList);
                } else {
                    setNotificationSettList(notiList);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    function handleUpdate() {
        apiService2('POST', myConfig.updateNotificationSettEP, { notificationList: notificationList })
            .then(response => {
                toast.success("Notification Settings Updated Successfully");
                setRefresh(!refresh);
            })
            .catch(error => {
                console.error(error);
            });
    }


    function handleChange(event, filed, i) {
        let notilistTemp = notificationList;
        notilistTemp[i][filed] = event.target.checked;
        setNotificationSettList([...notilistTemp]);
    }

    return (
        <Box m="10px 20px 20px 20px">
            <Box
                m="20px 0 0 0"
                height="70vh">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ textAlign: "left" }}>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>SMS</TableCell>
                                <TableCell>Whatsapp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notificationList.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell> <Checkbox
                                        labelStyle={{ color: 'white' }}
                                        iconStyle={{ fill: 'white' }}
                                        color="secondary"
                                        checked={row.isEmail}
                                        onChange={(event) => handleChange(event, 'isEmail', index)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    /></TableCell>
                                    <TableCell><Checkbox
                                        labelStyle={{ color: 'white' }}
                                        iconStyle={{ fill: 'white' }}
                                        color="secondary"
                                        checked={row.isSMS}
                                        onChange={(event) => handleChange(event, 'isSMS', index)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    /></TableCell>
                                    <TableCell> <Checkbox
                                        labelStyle={{ color: 'white' }}
                                        iconStyle={{ fill: 'white' }}
                                        color="secondary"
                                        checked={row.isWhatsApp}
                                        onChange={(event) => handleChange(event, 'isWhatsApp', index)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button type="button" variant="contained" color="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                </DialogActions>
            </Box>
        </Box>
    );
};

export default NotificationSett;
